import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'

import { Layout } from '../components/common'
import { MetaData } from '../components/common/meta'
import HeroPhoto from '../components/sections/hero-photo'
import OurServices from '../components/sections/our-services'
import SectionHeader from '../components/common/section-header'
import TeamMember from '../components/common/team-member'
import ContactForm from '../components/sections/contact-form'

import Slider from 'react-slick'

import '../styles/pages/page-aboutus.scss'
import schema from '../utils/schema/general'
import personSchema from '../utils/schema/persons'

const pageAboutus = ({ location }) => {
  let slider
  let settings = {
    dots: false,
    arrows:false,
    centerMode: true,
    centerPadding: '0',
    lazyload: "ondemand",
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    className: "aboutus-office__slider",
    responsive: [
      {
        breakpoint: 544,
        settings: {
          vertical: true
        }
      }
    ]
  };

  let previous = () => {slider.slickPrev()}
  let next = () => {slider.slickNext()}

  let data = useStaticQuery(graphql`{
    ghostPage: ghostPage(tags: {elemMatch: {name: {eq: "#page-aboutus"}}}) {
      ...GhostPageFields
    }
    hero: file(relativePath: {eq: "hero/hero-aboutus.jpg"}) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: FULL_WIDTH)
      }
    }
    logoGrey: file(relativePath: {eq: "tp_logogrey.png"}) {
      childImageSharp {
        gatsbyImageData(width: 700, layout: CONSTRAINED)
      }
    }
    office1: file(relativePath: {eq: "aboutus/office/Office1.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 490, layout: CONSTRAINED)
      }
    }
    office2: file(relativePath: {eq: "aboutus/office/Office2.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 490, layout: CONSTRAINED)
      }
    }
    office3: file(relativePath: {eq: "aboutus/office/Office3.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 490, layout: CONSTRAINED)
      }
    }
    office4: file(relativePath: {eq: "aboutus/office/Office4.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 490, layout: CONSTRAINED)
      }
    }
    aggelos: file(relativePath: {eq: "aboutus/team/aggelos.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    andy: file(relativePath: {eq: "aboutus/team/andy.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    david: file(relativePath: {eq: "aboutus/team/david.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    ele: file(relativePath: {eq: "aboutus/team/ele.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    gio: file(relativePath: {eq: "aboutus/team/gio.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    john: file(relativePath: {eq: "aboutus/team/john-mantas.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    kim: file(relativePath: {eq: "aboutus/team/kim.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    stauros: file(relativePath: {eq: "aboutus/team/stauros.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    panos: file(relativePath: {eq: "aboutus/team/panos-makris.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    euaggelos: file(relativePath: {eq: "aboutus/team/euaggelos-kolaitis.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    mandreakis: file(relativePath: {eq: "aboutus/team/manos-andreakis.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    vmarkou: file(relativePath: {eq: "aboutus/team/vana-markou.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    amichalopoulos: file(
      relativePath: {eq: "aboutus/team/angelos-michalopoulos.jpg"}
    ) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    prellos: file(relativePath: {eq: "aboutus/team/petros-rellos.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    glouvaris: file(relativePath: {eq: "aboutus/team/george-louvaris.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    cgkinis: file(relativePath: {eq: "aboutus/team/christos-gkinis.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    akoutroulis: file(relativePath: {eq: "aboutus/team/andreas-koutroulis.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    dkousoulis: file(relativePath: {eq: "aboutus/team/dimitris-kousoulis.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    dpantazopoulos: file(
      relativePath: {eq: "aboutus/team/dionysis-pantazopoulos.jpg"}
    ) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    epanagiotidis: file(
      relativePath: {eq: "aboutus/team/efthymios-panagiotidis.jpg"}
    ) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    gbessis: file(relativePath: {eq: "aboutus/team/george-bessis.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    gmantis: file(relativePath: {eq: "aboutus/team/grigoris-mantis.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    jgiannousis: file(relativePath: {eq: "aboutus/team/john-giannousis.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    kfilios: file(relativePath: {eq: "aboutus/team/konstantinos-filios.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    nchorinopoulou: file(relativePath: {eq: "aboutus/team/nicol-chorinopoulou.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    smichalas: file(relativePath: {eq: "aboutus/team/stefanos-michalas.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    sstamoulis: file(relativePath: {eq: "aboutus/team/stefanos-stamoulis.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    szouzaneas: file(relativePath: {eq: "aboutus/team/stratos-zouzaneas.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
		agathi: file(relativePath: {eq: "aboutus/team/agathi-pantechi.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
		amarouli: file(relativePath: {eq: "aboutus/team/alexandra-marouli.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
		stheodosopoulou: file(relativePath: {eq: "aboutus/team/stela-theodosopoulou.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
		thgennimata: file(relativePath: {eq: "aboutus/team/theodora-gennimata.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
  }
  `)
  const image = getImage(data.logoGrey)
  const bgImage = convertToBgImage(image)
	const page = data.ghostPage

  return <>
    <MetaData
      data={data}
      location={location}
      type="website"
    />
    <Helmet>
      <style type="text/css">{`${page.codeinjection_styles}`}</style>
      <script type="application/ld+json">{JSON.stringify(schema.organization)}</script>
    </Helmet>
    <Layout>
      <article className="page-aboutus">
        <HeroPhoto
          headerTitle={"About Us"}
          headerSubtitle={"Το μυστικό της επιτυχίας. Να είσαι διαφορετικός από τους άλλους."}
          ctaImage={data.hero.childImageSharp.gatsbyImageData}
        />
        <BackgroundImage
          Tag="section"
          className="aboutus__intro"
          {...bgImage}
        >
          <div className="dec-section--line-vertical">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 offset-lg-3 text-align-center">
                  <p>Τα πιο όμορφα πράγματα στον κόσμο τα κάνουν οι παρέες. Και εμείς είμαστε μια παρέα ανθρώπων που τους αρέσει πολύ να φτιάχνουν νέες εικόνες, να χτίζουν καινούργια websites, να φωτογραφίζουν, να δημιουργούν μοναδικά έργα, να σχεδιάζουν άψογα λογότυπα και να γράφουν τη γλώσσα προγραμματισμού της επιτυχίας.</p>
                  <p>Το όραμα των πελατών μας είναι και δικό μας.  Γι’ αυτό γνωρίζουμε πολύ καλά, μέσα από τον επαγγελματισμό και την εμπειρία μας, τι πρέπει να κάνουμε σε κάθε project.  Με εξειδικευμένες εφαρμογές και εργαλεία marketing, παρέχουμε υπηρεσίες αποκλειστικά σχεδιασμένες για κάθε ανάγκη στοχευμένης επικοινωνίας, για κάθε ανάγκη δημιουργίας.</p>
                  <p>Το σύνολο των projects που έχουμε υλοποιήσει αποδεικνύει τη διάθεση δημιουργίας μοναδικών αποτελεσμάτων και την ικανότητα ως ομάδα να αντιλαμβανόμαστε τις ιδιαίτερες απαιτήσεις κάθε έργου. Η επιτυχία των πελατών μας είναι για εμάς καθημερινή επιβράβευση.</p>
                  <p>Οι διακρίσεις και οι βραβεύσεις όλα αυτά τα χρόνια, μας προσφέρουν την ενέργεια να συνεχίσουμε το ίδιο δυναμικά στο μέλλον, αλλά και τη δύναμη να υπηρετήσουμε τις αξίες μας.</p>
                  <strong>Think Plus γιατί όλα είναι εφικτά με υπομονή και επιμονή!</strong>
                </div>
              </div>
            </div>
          </div>
        </BackgroundImage>
        <section className="aboutus__tools text-align-center">
          <div className="container">
            <div className="row">
              <div className="col">
                <h2 className="dec-header--underline">The Right Tools. The Right Solution.</h2>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <p className="aboutus__tools-text">Στην Think Plus σχεδιάζουμε για εσάς την κατάλληλη στρατηγική marketing, προσδιορίζοντας την επιλογή των αντικειμενικών στόχων και την κατανομή των πόρων. Μην ξεχνάτε πως η στρατηγική marketing, είναι αποτελεσματικότερη όταν αποτελεί αναπόσπαστο συστατικό της γενικής σταθερής στρατηγικής, καθορίζοντας πως η επιχείρηση ή το brand name θα προσελκύσει επιτυχημένα πελάτες, πιθανούς αγοραστές και ανταγωνιστές στο χώρο της αγοράς.</p>
              </div>
            </div>
          </div>
        <OurServices decoration={false} subtitle={true} />
        </section>
        <section className="aboutus__offices">
          <SectionHeader
            title={"OUR OFFICE"}
            text={"makes our day"}
          />
          <div className="container">
            <Slider ref={c => {slider = c}} {...settings}>
              <GatsbyImage image={data.office2.childImageSharp.gatsbyImageData} alt="" />
              <GatsbyImage image={data.office3.childImageSharp.gatsbyImageData} alt="" />
              <GatsbyImage image={data.office1.childImageSharp.gatsbyImageData} alt="" />
              <GatsbyImage image={data.office4.childImageSharp.gatsbyImageData} alt="" />
            </Slider>
            <div className="row no-gutters">
              <div className="col text-align-right">
                <button className="aboutus-office__slider-prev" rel="prev" aria-label="Previous" onClick={previous}><div className="btn-triangle btn-triangle--left"></div></button>
              </div>
              <div className="col">
                <button className="aboutus-office__slider-next" rel="next" aria-label="Next" onClick={next}><div className="btn-triangle btn-triangle--right"></div></button>
              </div>
            </div>
          </div>
        </section>
        <section className="aboutus__stats">
          <div className="container">
            <div className="aboutus__stat">
              <p className="h1">200</p>
              <p className="dec-text__line--left">satisfied clients</p>
            </div>
            <div className="aboutus__stat">
              <p className="h1">8500</p>
              <p className="dec-text__line--left">cups of coffee</p>
            </div>
            <div className="aboutus__stat">
              <p className="h1">11000</p>
              <p className="dec-text__line--left">development hours</p>
            </div>
          </div>
        </section>
        <section className="aboutus__vision">
          <div className="container">
            <div className="row align-items-center vision--creative">
                <h2 className="dec-text__line--bottom">WE ARE<br /> CREATIVE</h2>
                <p>Καινοτομία και ανάπτυξη μέσα από τη συνεχή έρευνα και αναζήτηση νέων τεχνολογικών εφαρμογών και πρωτοποριακών μεθόδων marketing, παραμένοντας συνεχώς στην πρώτη γραμμή της τεχνολογίας.</p>
            </div>
            <div className="row align-items-center vision--determined">
                <p className="text-align-right ">Η ικανοποίηση των πελατών μας που εξασφαλίζεται μέσα από τις 6 αρχές της Think Plus: Συνέργεια – Συνέπεια – Ενημέρωση – Καινοτομία – Αποδοτικότητα – Μοναδικότητα.</p>
                <h2 className="dec-text__line--top">WE ARE <br />DETERMINED</h2>
            </div>
          </div>
        </section>
        <section className="aboutus__team">
          <SectionHeader
            title={"OUR TEAM"}
            text={"We don't have a style.<br>We have standards."}
          />
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={data.aggelos.childImageSharp.gatsbyImageData}
                  memberName={'Άγγελος Κωνσταντόπουλος'}
                  memberTitle={'co-founder'}
                  memberSchema={personSchema.aggelos}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={data.david.childImageSharp.gatsbyImageData}
                  memberName={'Δαυίδ Τρακαντζίδης'}
                  memberTitle={'co-founder & ceo'}
                  memberSchema={personSchema.david}
                />
              </div>
							<div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={data.agathi.childImageSharp.gatsbyImageData}
                  memberName={'Αγαθή Παντέχη'}
                  memberTitle={'accounting manager'}
                  memberSchema={personSchema.agathi}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={data.kim.childImageSharp.gatsbyImageData}
                  memberName={'Κιμ Σούλερ'}
                  memberTitle={'marketing director'}
                  memberSchema={personSchema.kim}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={data.ele.childImageSharp.gatsbyImageData}
                  memberName={'Ελεάννα Σωτηρίου'}
                  memberTitle={'art director'}
                  memberSchema={personSchema.eleanna}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={data.mandreakis.childImageSharp.gatsbyImageData}
                  memberName={'Μάνος Ανδρεάκης'}
                  memberTitle={'visual designer'}
                  memberSchema={personSchema.mandreakis}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={data.nchorinopoulou.childImageSharp.gatsbyImageData}
                  memberName={'Νικόλ Χωρινοπούλου'}
                  memberTitle={'visual designer'}
                  memberSchema={personSchema.nchorinopoulou}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={data.dkousoulis.childImageSharp.gatsbyImageData}
                  memberName={'Δημήτρης Κουσούλης'}
                  memberTitle={'creative director'}
                  memberSchema={personSchema.dkousoulis}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={data.gbessis.childImageSharp.gatsbyImageData}
                  memberName={'Γιώργος Μπέσσης'}
                  memberTitle={'media director'}
                  memberSchema={personSchema.gbessis}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={data.gio.childImageSharp.gatsbyImageData}
                  memberName={'Γεωργία Γεροκώστα'}
                  memberTitle={'digital account manager'}
                  memberSchema={personSchema.georgia}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={data.stauros.childImageSharp.gatsbyImageData}
                  memberName={'Σταύρος Νικόλας'}
                  memberTitle={'digital account manager'}
                  memberSchema={personSchema.stauros}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={data.vmarkou.childImageSharp.gatsbyImageData}
                  memberName={'Βάνα Μάρκου'}
                  memberTitle={'digital account manager'}
                  memberSchema={personSchema.vmarkou}
                />
              </div>
							<div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={data.amarouli.childImageSharp.gatsbyImageData}
                  memberName={'Αλεξάνδρα Μαρούλη'}
                  memberTitle={'digital account manager'}
                  memberSchema={personSchema.amarouli}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={data.kfilios.childImageSharp.gatsbyImageData}
                  memberName={'Κωνσταντίνος Φίλιος'}
                  memberTitle={'vp of engineering'}
                  memberSchema={personSchema.kfilios}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={data.amichalopoulos.childImageSharp.gatsbyImageData}
                  memberName={'Άγγελος Μιχαλόπουλος'}
                  memberTitle={'director of engineering'}
                  memberSchema={personSchema.amichalopoulos}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={data.dpantazopoulos.childImageSharp.gatsbyImageData}
                  memberName={'Διονύσης Πανταζόπουλος'}
                  memberTitle={'technical lead'}
                  memberSchema={personSchema.dpantazopoulos}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={data.euaggelos.childImageSharp.gatsbyImageData}
                  memberName={'Ευάγγελος Κολαΐτης'}
                  memberTitle={'senior software engineer'}
                  memberSchema={personSchema.euaggelos}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={data.prellos.childImageSharp.gatsbyImageData}
                  memberName={'Πέτρος Ρέλλος'}
                  memberTitle={'senior software engineer'}
                  memberSchema={personSchema.prellos}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={data.john.childImageSharp.gatsbyImageData}
                  memberName={'Γιάννης Μαντάς'}
                  memberTitle={'senior software engineer'}
                  memberSchema={personSchema.john}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={data.akoutroulis.childImageSharp.gatsbyImageData}
                  memberName={'Ανδρέας Κουτρούλης'}
                  memberTitle={'software engineer'}
                  memberSchema={personSchema.akoutroulis}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={data.jgiannousis.childImageSharp.gatsbyImageData}
                  memberName={'Νίκος Μακρής'}
                  memberTitle={'software engineer'}
                  memberSchema={personSchema.jgiannousis}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={data.epanagiotidis.childImageSharp.gatsbyImageData}
                  memberName={'Ευθύμιος Παναγιωτίδης'}
                  memberTitle={'software engineer'}
                  memberSchema={personSchema.epanagiotidis}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={data.smichalas.childImageSharp.gatsbyImageData}
                  memberName={'Στέφανος Μιχαλάς'}
                  memberTitle={'software engineer'}
                  memberSchema={personSchema.smichalas}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={data.sstamoulis.childImageSharp.gatsbyImageData}
                  memberName={'Στέφανος Σταμούλης'}
                  memberTitle={'software engineer'}
                  memberSchema={personSchema.sstamoulis}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={data.panos.childImageSharp.gatsbyImageData}
                  memberName={'Πάνος Μακρής'}
                  memberTitle={'performance marketing manager'}
                  memberSchema={personSchema.panos}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={data.andy.childImageSharp.gatsbyImageData}
                  memberName={'Ανδρέας Δίζης'}
                  memberTitle={'head photographer'}
                  memberSchema={personSchema.andreas}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={data.glouvaris.childImageSharp.gatsbyImageData}
                  memberName={'Γιώργος Λούβαρης'}
                  memberTitle={'photographer'}
                  memberSchema={personSchema.glouvaris}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={data.cgkinis.childImageSharp.gatsbyImageData}
                  memberName={'Χρήστος Γκίνης'}
                  memberTitle={'video production manager'}
                  memberSchema={personSchema.cgkinis}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={data.gmantis.childImageSharp.gatsbyImageData}
                  memberName={'Γρηγόρης Μάντης'}
                  memberTitle={'videographer'}
                  memberSchema={personSchema.gmantis}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={data.szouzaneas.childImageSharp.gatsbyImageData}
                  memberName={'Στράτος Ζουζανέας'}
                  memberTitle={'videographer'}
                  memberSchema={personSchema.szouzaneas}
                />
              </div>
							<div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={data.stheodosopoulou.childImageSharp.gatsbyImageData}
                  memberName={'Στέλα Θεοδωσοπούλου'}
                  memberTitle={'senior copywriter'}
                  memberSchema={personSchema.stheodosopoulou}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={data.thgennimata.childImageSharp.gatsbyImageData}
                  memberName={'Θεοδώρα Γεννηματά'}
                  memberTitle={'copywriter'}
                  memberSchema={personSchema.thgennimata}
                />
              </div>
            </div>
          </div>
        </section>
        <ContactForm />
      </article>
    </Layout>
  </>;
}

export default pageAboutus
