module.exports = {
  aggelos: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Άγγελος Κωνσταντόπουλος",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/aggelos.jpg",
    "jobTitle": "Co-founder",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  david: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Δαυίδ Τρακαντζίδης",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/david.jpg",
    "jobTitle": "Co-founder & CEO",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
	agathi: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Αγαθή Παντέχη",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/agathi-pantechi.jpg",
    "jobTitle": "Accounting Manager",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  kim: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Κίμ Σούλερ",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/kim.jpg",
    "jobTitle": "Marketing Director",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  eleanna: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Ελεάννα Σωτηρίου",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/ele.jpg",
    "jobTitle": "Senior Art Director",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  georgia: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Γεωργία Γεροκώστα",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/gio.jpg",
    "jobTitle": "digital account manager",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  stauros: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Σταύρος Νικόλας",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/stauros.jpg",
    "jobTitle": "digital account manager",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  andreas: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Ανδρέας Δίζης",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/andy.jpg",
    "jobTitle": "Head Photographer",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  panos: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Panos Makris",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/panos-makris.jpg",
    "sameAs": [
      "https://www.linkedin.com/in/panos-makris-8aa02a27/",
      "https://github.com/panosmakris",
      "https://optimistic-volhard-c69d07.netlify.com/"
    ],
    "jobTitle": "Performance Marketing Manager",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  john: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Γιάννης Μαντάς",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/john-mantas.jpg",
    "sameAs": [
      "https://www.linkedin.com/in/john-mantas/",
      "https://johnmantas.com",
      "https://github.com/john-mantas",
      "https://codepen.io/john-mantas/"
    ],
    "jobTitle": "Senior Software Engineer",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  euaggelos: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Ευάγγελος Κολαΐτης",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/euaggelos-kolaitis.jpg",
    "jobTitle": "Senior Software Engineer",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  mandreakis: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Μάνος Ανδρεάκης",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/manos-andreakis.jpg",
    "jobTitle": "Visual designer",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  vmarkou: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Βάνα Μάρκου",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/vana-markou.jpg",
    "jobTitle": "Vibe manager",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  amichalopoulos: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Άγγελος Μιχαλόπουλος",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/angelos-michalopoulos.jpg",
    "jobTitle": "Director of Engineering",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  prellos: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Πέτρος Ρέλλος",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/petros-rellos.jpg",
    "jobTitle": "Senior software engineer",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  glouvaris: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Γιώργος Λούβαρης",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/george-louvaris.jpg",
    "jobTitle": "Photographer",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  cgkinis: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Χρήστος Γκίνης",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/christos-gkinis.jpg",
    "jobTitle": "Video Production Manager",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  akoutroulis: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Ανδρέας Κουτρούλης",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/akoutroulis.jpg",
    "jobTitle": "Software engineer",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  dkousoulis: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Δημήτρης Κουσούλης",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/dkousoulis.jpg",
    "jobTitle": "Creative director",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  dpantazopoulos: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Διονύσης Πανταζόπουλος",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/dpantazopoulos.jpg",
    "jobTitle": "Technical lead",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  epanagiotidis: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Ευθύμιος Παναγιωτίδης",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/epanagiotidis.jpg",
    "jobTitle": "Software engineer",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  gbessis: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Γιώργος Μπέσσης",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/gbessis.jpg",
    "jobTitle": "Media director",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  gmantis: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Γρηγόρης Μάντης",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/gmantis.jpg",
    "jobTitle": "Videographer",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  jgiannousis: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Νίκος Μακρής",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/jgiannousis.jpg",
    "jobTitle": "Software engineer",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  kfilios: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Κωνσταντίνος Φίλιος",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/kfilios.jpg",
    "jobTitle": "VP of engineering",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  nchorinopoulou: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Νικόλ Χωρινοπούλου",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/nchorinopoulou.jpg",
    "jobTitle": "Visual designer",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  smichalas: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Στέφανος Μιχαλάς",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/smichalas.jpg",
    "jobTitle": "Software engineer",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  sstamoulis: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Στέφανος Σταμούλης",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/sstamoulis.jpg",
    "jobTitle": "Software engineer",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  szouzaneas: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Στράτος Ζουζανέας",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/szouzaneas.jpg",
    "jobTitle": "Videographer",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
	amarouli: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Αλεξάνδρα Μαρούλη",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/alexandra-marouli.jpg",
    "jobTitle": "Digital Account Manager",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
	stheodosopoulou: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Στέλα Θεοδωσοπούλου",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/stela-theodosopoulou.jpg",
    "jobTitle": "Senior Copywriter",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
	thgennimata: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Θεοδώρα Γεννηματά",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/theodora-gennimata.jpg",
    "jobTitle": "Copywriter",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  }
}